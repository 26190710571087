import request from '@/utils/request'
// VUE_APP_BASE_API  5010
// VUE_APP_SN_API 5012
// 人员规模统计
export function getStaffSizeChart(data) {
    return request({
        url: '/v1/WebStats/GetCountStatsOfPersonnelScaleV1',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 创立年限
export function getEstablishmentPeriodChart(data) {
    return request({
        url: '/v1/WebStats/GetCountStatsOfYearV1',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 上市挂牌企业
export function getListedCompaniesChart(data) {
    return request({
        url: 'v1/WebStats/GetCountStatsOfStockV2',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 优质中小企业
export function getHighQualityEnterpriseChart(data) {
    return request({
        url: 'v1/WebStats/GetCompanyXJREntOfTagV1',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 科技资质统计
export function getTechnologyQualificationsChart(data) {
    return request({
        url: 'v1/WebStats/GetCountStatsOfStockV2',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 办件排名统计 
export function getHandlingDocuments(data) {
    return request({
        url: 'ProcessingStatistics/GetProcessingRanking',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}
// 行业分布 
export function getIndustryCountData(data) {
    return request({
        url: 'v1/WebStats/GetIndustryCountData',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}
// 星级评价统计
export function getStarRating(data) {
    return request({
        url: '/ProcessingStatistics/GetStarRanking',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 全区办件总数
export function getTotalProcessingRanking(params) {
    return request({
        url: '/ProcessingStatistics/GetTotalProcessingRanking',
        method: 'get',
        params,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 全区优质企业数量
export function getTotalHighQualityEnterpriseNum(data) {
    return request({
        url: '/v1/WebStats/SearchCustomerList?typeId=3',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 获取企业列表
export function searchCustomerList(data) {
    return request({
        url: '/v1/WebStats/SearchCustomerList?typeId=3',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 获取开福区热力图
export function getHeatMap(data) {
    return request({
        url: '/v1/WebStats/GetKaiFuHotDta',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 平台用户数
export function getTotalUserCount(params) {
    return request({
        url: '/ProcessingStatistics/GetTotalUserCount',
        method: 'get',
        params,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 认证企业数
export function getTotalAuthenticatedEnterpriseCount(params) {
    return request({
        url: '/ProcessingStatistics/GetTotalAuthenticatedEnterpriseCount',
        method: 'get',
        params,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 日访问量
export function getTotalVisits(params) {
    return request({
        url: '/ProcessingStatistics/GetTotalVisits',
        method: 'get',
        params,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 今日全区办件总量
export function getTotalProcessingRankingAndToday(params) {
    return request({
        url: '/ProcessingStatistics/GetTotalProcessingRankingAndToday',
        method: 'get',
        params,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 福企名片企业数 
export function getTotalEnterpriseCount(data) {
    return request({
        url: '/v1/WebStats/SearchCustomerListCount',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 功能模块活跃度
export function getSysVisits(data) {
    return request({
        url: '/Visits/GetSysVisits',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 活跃度变化趋势
export function getHomeVisitsByTimeSpan(data) {
    return request({
        url: '/ProcessingStatistics/GetHomeVisitsByTimeSpan',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}
//获取行业
export function getIndustryTreeData(data) {
    return request({
        url: '/v1/CountryIndustry/GetIndustryTreeData',
        method: 'get',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 新增注册企业统计数据
export function GetRegisteredCompanyCountOfMonth(data) {
    return request({
        url: '/v1/WebIndustrialChain/GetRegisteredCompanyCountOfMonth',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}
// 获取指定月数的注销企业统计数据
export function GetClosedCompanyCountOfMonth(data) {
    return request({
        url: '/v1/WebIndustrialChain/GetClosedCompanyCountOfMonth',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 获取指定月数的迁出企业统计数据
export function GetMoveOutCompanyCountOfMonth(data) {
    return request({
        url: '/v1/WebIndustrialChain/GetMoveOutCompanyCountOfMonth',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}

// 获取福企名片企业列表
export function SearchCustomerList(data) {
    return request({
        url: 'v1/WebStats/SearchCustomerList?typeId=3',
        method: 'post',
        data,
        baseURL: process.env.VUE_APP_SN_API
    })
}
