<template>
	<div class="img-list">
		<template v-if="showFileList">
			<template v-if="type === 'image'">
				<div v-for="(item, i) in list" :key="i">
					<img
						v-if="item"
						v-lazy="item"
						:data-index="i"
						:style="'width:' + width + 'px;height:' + height + 'px;object-fit:' + objectFit"
						class="img-thumb"
						@click="open(i)"
					/>
				</div>
			</template>
			<template v-if="type === 'video'">
				<div v-for="(item, i) in list" :key="i" class="video-item" :style="'width:' + width + 'px;height:' + height + 'px'" @click="open(i)">
					<video
						:data-index="i"
						:controls="false"
						:src="item"
						:style="'width:' + width + 'px;height:' + height + 'px;object-fit:' + objectFit"
						class="video-item_thumb"
					/>
					<i class="el-icon-video-play video-item_icon" />
				</div>
			</template>
		</template>
		<div v-if="isShow" class="img-popup">
			<div class="img-popup-mask" />
			<div class="img-popup-inner">
				<div v-if="list.length > 1" class="img-popup-count">{{ currentIndex + 1 }} / {{ list.length }}</div>
				<i class="icon-close el-icon-close" @click="close" />
				<div v-if="currentIndex > 0" class="img-popup-icon prev" @click="change('prev')">
					<i class="el-icon-arrow-left" />
				</div>
				<img v-if="type === 'image'" :src="list[currentIndex]" :data-index="currentIndex" class="img-popup-thumb" alt="" @click="close" />
				<video v-else class="video" :data-index="currentIndex" controls autoplay :src="list[currentIndex]" />
				<!-- <i class="el-icon-loading" /> -->
				<div v-if="currentIndex < list.length - 1" class="img-popup-icon next" @click="change('next')">
					<i class="el-icon-arrow-right" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FilePreview', // 文件预览，支持图片和视频预览
	props: {
		list: {
			type: Array,
			default: () => {
				return [];
			},
		},
		showFileList: {
			type: Boolean,
			default: true,
		},
		// image图片；video视频
		type: {
			type: String,
			default: 'image',
		},
		index: {
			type: Number,
			default: 0,
		},
		width: {
			type: Number,
			default: 50,
		},
		height: {
			type: Number,
			default: 50,
		},
		objectFit: {
			type: String,
			default: 'cover',
		},
	},
	data() {
		return {
			currentIndex: 0,
			isShow: false,
		};
	},
	watch: {
		index: {
			handler(newValue) {
				this.currentIndex = newValue;
			},
			immediate: true,
		},
	},
	methods: {
		close() {
			this.isShow = false;
			this.$emit('close');
		},
		open(index = 0) {
			this.currentIndex = index;
			this.isShow = true;
			this.$emit('open', index);
		},
		change(type) {
			if (type === 'prev') {
				if (this.currentIndex <= 0) {
					this.$message.warning('已经是第一个啦！');
					return;
				}
				this.currentIndex--;
			} else {
				if (this.currentIndex >= this.list.length - 1) {
					this.$message.warning('已经是最后一个啦！');
					return;
				}
				this.currentIndex++;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.img-list {
	display: flex;
	flex-wrap: wrap;
	.img-thumb {
		margin: 3px;
		flex-shrink: 0;
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: 4px;
		cursor: pointer;
	}
}

.video-item {
	position: relative;
	margin: 3px;
	flex-shrink: 0;
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 4px;
	cursor: pointer;
	overflow: hidden;
	&_thumb {
		outline-style: none;
	}
	&_icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-size: 20px;
	}
}

.img-popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	&-mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.9);
	}
	&-count {
		position: fixed;
		top: 0;
		line-height: 30px;
		padding: 0 15px;
		font-size: 16px;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 0 0 10px 10px;
		z-index: 99999;
	}
	&-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: inherit;
	}
	&-thumb {
		max-width: 95%;
		max-height: 95%;
		z-index: inherit;
	}
	.el-icon-loading {
		position: fixed;
		top: 50%;
		left: 50%;
		font-size: 40px;
		margin-left: -20px;
		margin-top: -20px;
	}
	.icon-close {
		position: fixed;
		top: 20px;
		right: 20px;
		font-size: 50px;
		color: #efefef;
		transition: 0.2s linear;
		&:hover {
			cursor: pointer;
			color: #fff;
			transform: rotate(180deg);
		}
	}
	&-icon {
		position: absolute;
		top: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		background-color: #efefef;
		transition: 0.2s linear;
		cursor: pointer;
		font-weight: bold;
		font-size: 30px;
		border-radius: 50%;
		z-index: 1003;
		&.prev {
			left: 15px;
		}
		&.next {
			right: 15px;
		}
		&:hover {
			background-color: #fff;
			color: #000;
		}
	}
}

.video {
	outline-style: none;
	width: 80%;
	height: 80vh;
	z-index: 999;
}
</style>
