<!--
文件上传通用组件
-->
<template>
	<div :id="uploadContainerId" class="com-upload">
		<el-upload
			:ref="uploadContainerId"
			:action="action"
			:name = "name"
			:headers="extraHeaders"
			:limit="limit"
			:file-list="fileList"
			:with-credentials="withCredentials"
			:drag="drag"
			:accept="fileAccept"
			:multiple="limit > 1 && multiple"
			:list-type="listType"
			:auto-upload="autoUpload"
			:show-file-list="showFileList"
			:on-preview="onPreview"
			:before-remove="beforeRemove"
			:on-remove="onRemove"
			:on-success="onSuccess"
			:on-error="onError"
			:on-progress="onProgress"
			:on-change="onChange"
			:before-upload="beforeUpload"
			:on-exceed="onExceed"
			:disabled="disabled"
		>
			<template v-if="listType === 'picture-card'">
				<i class="el-icon-plus com-upload_icon" />
				<span v-if="desc" class="com-upload_desc">{{ desc }}</span>
			</template>
			<slot />
		</el-upload>
		<template v-if="['image', 'video', 'audio'].includes(fileType)">
			<file-preview ref="filePreview" :type="fileType" :index="currentPreviewIndex" :show-file-list="false" :list="previewFileUrls" />
		</template>
		<!-- vueCropper 剪裁图片实现-->
		<!-- <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body @close="onClose()">
			<div class="cropper-content">
				<div class="cropper" style="text-align: center">
					<vue-cropper
						ref="cropper"
						:img="option.img"
						:outputSize="option.size"
						:outputType="option.outputType"
						:info="true"
						:full="option.full"
						:canMove="option.canMove"
						:canMoveBox="option.canMoveBox"
						:original="option.original"
						:autoCrop="option.autoCrop"
						:fixed="option.fixed"
						:fixedNumber="option.fixedNumber"
						:centerBox="option.centerBox"
						:infoTrue="option.infoTrue"
						:fixedBox="option.fixedBox"
						:autoCropWidth="option.autoCropWidth"
						:autoCropHeight="option.autoCropHeight"
					/>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="onClose()">取 消</el-button>
				<el-button type="primary" @click="onCropper" :loading="loading">确认</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
// import { VueCropper } from 'vue-cropper';
import { getToken } from '@/utils/auth'
import filePreview from "../file-preview/file-preview.vue";
export default {
	name: 'ComUploadFile', // 上传文件组件
	components: {
		// VueCropper,
		filePreview
	},
	props: {
		value: {
			type: Array,
			default: () => {
				return [
					// {
					//   name: '', // 文件名称
					//   url: '', // 图片列表/视频封面
					//   fileUrl: '' // 文件路径（如视频播放路径）
					// }
				];
			},
		},
		// 必选参数，上传的地址
		action: {
			type: String,
			default:process.env.VUE_APP_BASE_API+'/Files/Upload',
		},
		// 设置上传的请求头部
		headers: {
			type: Object,
			default: () => {
				return {};
			},
		},
		// 文字提示
		desc: {
			type: String,
			default: '选择文件',
		},
		// 文件上传类型：video视频 image图片 pdf  other其他
		fileType: {
			type: String,
			default: 'image',
		},
		// 文件限制大小，单位M
		fileSize: {
			type: Number,
			default: 200,
		},
		// 最大允许上传个数
		limit: {
			type: Number,
			default: 1,
		},
		// 是否支持多选文件
		multiple: {
			type: Boolean,
			default: true,
		},
		// 上传时附带的额外参数
		data: {
			type: Object,
			default: () => {
				return {};
			},
		},
		// 上传的文件字段名
		name: {
			type: String,
			default: 'files',
		},
		// 支持发送 cookie 凭证信息
		withCredentials: {
			type: Boolean,
			default: false,
		},
		// 是否显示已上传文件列表
		showFileList: {
			type: Boolean,
			default: true,
		},
		// 是否启用拖拽上传
		drag: {
			type: Boolean,
			default: true,
		},
		// 接受上传的文件类型（thumbnail-mode 模式下此参数无效）https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept 输入*表示不受限
		accept: {
			type: String,
			default: '',
		},
		// 文件列表的类型 text/picture/picture-card
		listType: {
			type: String,
			default: 'picture-card',
		},
		// 是否在选取文件后立即进行上传
		autoUpload: {
			type: Boolean,
			default: true,
		},
		//是否禁用
		disabled: {
			type: Boolean,
			default: false,
		},
		//裁剪开启，只支持单图
		cropper: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			fileList: [],
			currentPreviewIndex: 0,
			dialogVisible: false,
			option: {
				img: 'https://pic1.zhimg.com/80/v2-366c0aeae2b4050fa2fcbfc09c74aad4_720w.jpg', // 裁剪图片的地址
				info: true, // 裁剪框的大小信息
				outputSize: 1, // 裁剪生成图片的质量
				outputType: 'png', // 裁剪生成图片的格式
				canScale: true, // 图片是否允许滚轮缩放
				autoCrop: true, // 是否默认生成截图框
				canMoveBox: true, // 截图框能否拖动
				autoCropWidth: 200, // 默认生成截图框宽度
				autoCropHeight: 200, // 默认生成截图框高度
				fixedBox: false, // 固定截图框大小 不允许改变
				fixed: true, // 是否开启截图框宽高固定比例
				fixedNumber: [1, 1], // 截图框的宽高比例
				full: false, // 是否输出原图比例的截图
				original: false, // 上传图片按照原始比例渲染
				centerBox: false, // 截图框是否被限制在图片里面
				infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
			},
			// 防止重复提交
			loading: false,
		};
	},
	computed: {
		uploadContainerId() {
			return 'uploadContainer' + new Date().getTime();
		},
		fileAccept() {
			if (this.accept) return this.accept;
			if (this.fileType === 'video') return 'video/*'; // video/mp4
			if (this.fileType === 'image') return 'image/png, image/jpeg'; // image/png, image/jpeg 11
			if (this.fileType === 'audio') return 'audio/mp3,audio/wav'; // audio/mp3 音频文件
			return '';
		},
		token() {
			return this.$store.getters['token'];
		},
		extraHeaders() {
			return Object.assign(
				{
					// 'content-type': 'multipart/form-data',
					'X-Token': getToken()
				},
				this.headers
			);
		},
		extraData() {
			const obj = { video: 1, image: 2, other: 3, pdf: 4, audio: 1 };
			return Object.assign({ fileType: obj[this.fileType || 'image'] }, this.data);
		},
		// 判断是否全部上传完毕
		isBusy() {
			const loadingList = this.fileList.filter((item) => item.status === 'uploading');
			return loadingList.length > 0;
		},
		// 预览的链接
		previewFileUrls() {
			return this.fileList.map((item) => item.fileUrl);
		},
	},
	watch: {
		value: {
			handler(val, oldVal) {
				// 数据回填
				if (((!oldVal || oldVal.length === 0) && val.length > 0) || (val.length === 0 && oldVal)) {
					this.fileList = [...val];
				}
			},
			immediate: true,
			deep: true,
		},
		fileList: {
			handler(val) {
				setTimeout(() => {
					const dom = `#${this.uploadContainerId} .el-upload`;
					const $dom = document.querySelector(dom);
					if (this.limit === val.length) {
						$dom ? ($dom.style.display = 'none') : '';
					} else {
						$dom ? ($dom.style.display = 'inline-block') : '';
					}
				}, 5);
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		// 手动上传
		submitUpload() {
			this.$refs[this.uploadContainerId].submit();
		},
		// 点击文件列表中已上传的文件时的钩子
		onPreview(file) {
			if (file.status === 'uploading') {
				this.$message.error('文件正在上传到服务器，请稍候');
				return;
			}
			if (['video', 'image', 'audio'].includes(this.fileType)) {
				const index = this.previewFileUrls.findIndex((item) => item === file.fileUrl);
				this.currentPreviewIndex = index > -1 ? index : 0;
				this.$refs.filePreview.open();
			} else {
				this.$message.error('该类型的文件不支持预览');
			}
			this.$emit('on-preview', file);
		},
		// 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
		beforeUpload(file) {
			this.$emit('before-upload', file);
			const isLimt = file.size / 1024 / 1024 < this.fileSize;
			if (!isLimt) {
				this.$message.error('大小不能超过 ' + this.fileSize + 'MB!');
			}
			return isLimt;
		},
		// 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
		beforeRemove(file) {
			const index = this.findIndex(file.uid);
			if (file.status === 'uploading') {
				this.fileList.splice(index, 1);
				this.abort(file);
				return false;
			}
		},
		// 文件列表移除文件时的钩子
		onRemove(file, fileList) {
			this.fileList = [...fileList];
			this.update();
			this.$emit('on-remove', file);
		},
		// 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
		onChange(file, fileList) {
			//是否开启图片裁剪功能
			if (this.cropper) {
				// 上传成功后将图片地址赋值给裁剪框显示图片
				this.$nextTick(async () => {
					// base64方式
					// this.option.img = await fileByBase64(file.raw)
					this.option.img = URL.createObjectURL(file.raw);
					this.loading = false;
					this.dialogVisible = true;
				});
			} else {
				// this.customUpload(file, fileList);
				this.$emit('on-change', file, fileList);
			}
		},
		// 文件上传时的钩子
		onProgress(event, file, fileList) {
			this.fileList = [...fileList];
			if (event.percent >= 99) {
				setTimeout(() => {
					const $process = document.querySelector(`#${this.uploadContainerId} .el-progress__text`);
					if ($process) $process.innerHTML = '99%';
				}, 0);
			}
			this.$emit('on-progress', event, file, fileList);
		},
		// 文件上传成功时的钩子
		onSuccess(response, file, fileList) {
			this.$emit('on-success', response, file, fileList);
			const { code, msg, result } = response;
			this.fileList = [...fileList];
			const index = this.findIndex(file.uid);
			if (code === 200) {
				this.fileList[index].fileUrl = result[0].filePath; // 文件链接
				this.fileList[index].url = result[0].filePath; // 文件链接
				this.fileList[index].fileId = result[0].id || 1; // 文件id
				this.fileList[index].fileName = result[0].fileName || ''; // 文件名称
				// if (this.fileType === 'video' || this.fileType === 'audio') {
				// 	this.fileList[index].url = data.cover; // 视频封面
				// }
			} else {
				this.fileList.splice(index, 1);
				this.$message.error(msg);
			}
			this.update();
		},
		// 文件上传失败时的钩子
		onError(err, file, fileList) {
			this.$message.error('文件「' + file.name + '」上传失败，请重新上传');
			this.$emit('on-error', err, file, fileList);
		},
		// 文件超出个数限制时的钩子
		onExceed(files, fileList) {
			this.$message.error('最多只能上传' + this.limit + '个文件');
			this.$emit('on-exceed', files, fileList);
		},
		findIndex(uid) {
			return this.fileList.findIndex((item) => item.uid === uid);
		},
		update() {
			this.$emit('input', this.fileList);
			this.$emit('on-update', this.fileList);
		},
		// 取消上传
		abort(file) {
			this.$refs[this.uploadContainerId].abort(file);
		},
		clear() {
			this.fileList = [];
		},
		clearFiles() {
			this.$refs[this.uploadContainerId].clearFiles();
		},
		//裁剪确认
		onCropper() {
			// 获取截图的 blob 数据
			this.$refs.cropper.getCropBlob((blob) => {
				console.log(blob, 'bbb');
				this.loading = true;
				this.dialogVisible = false;
				//this.previewImg = URL.createObjectURL(blob);
				//this.isPreview = true;
				let files = new window.File([blob], 'head.png', { type: blob.type });
				const formData = new FormData();
				formData.append('file', files);
				this.$api.user
					.upload(formData)
					.then((res) => {
						this.fileList = [{ fileUrl: res.data.objectUrl, url: res.data.objectUrl }];
						this.$emit('input', this.fileList);
						this.$emit('on-success', {}, files, this.fileList);
						this.$emit('on-update', this.fileList);
					})
					.catch((e) => {
						console.log(e, 'eee');
						this.fileList = [];
						this.$message.error('上传失败，请重新上传');
					});
			});
			// 获取截图的 base64 数据
			// this.$refs.cropper.getCropData(data => {
			//     console.log(data)
			// })
		},
		onClose() {
			this.fileList.splice(0, 1);
			this.dialogVisible = false;
		},
	},
};
</script>

<style lang="scss">
// .order-detail {
//   & /deep/ .el-form-item {
//     margin: 0;
//     .el-input {
//       margin-bottom: 22px;
//     }
//   }
// }
// /deep/ .el-dialog .el-icon-close{
//   color: black;
// }
.com-upload {
	.el-icon-close {
		color: black;
	}
	&_icon {
		font-size: 40px !important;
	}
	&_desc {
		margin-top: 5px;
		font-size: 14px;
		color: #8c939d;
		line-height: 1;
	}
	.el-upload-dragger {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		border: none;
	}
	.el-upload-list__item {
		display: inline-flex;
		outline-style: none;
	}
	img[src=''],
	img:not([src]) {
		opacity: 0;
	}
	.crop-dialog {
		.cropper-content {
			padding: 0 40px;

			.cropper {
				width: auto;
				height: 350px;
			}
		}

		.action-box {
			padding: 25px 40px 0 40px;
			display: flex;
			justify-content: center;

			button {
				width: 80px;
				margin-right: 15px;
			}
		}

		.dialog-footer {
			button {
				width: 100px;
			}
		}
	}
}
.crop-dialog {
	.cropper-content {
		padding: 0 40px;

		.cropper {
			width: auto;
			height: 350px;
		}
	}

	.action-box {
		padding: 25px 40px 0 40px;
		display: flex;
		justify-content: center;

		button {
			width: 80px;
			margin-right: 15px;
		}
	}

	.dialog-footer {
		button {
			width: 100px;
		}
	}
}
</style>
