<template>
  <div>
    <div class="wrap">
      <!-- 工具栏 -->
      <Toolbar
        class="toolbar"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->
      <Editor
        class="editor"
        ref="editor"
        :defaultConfig="editorConfig"
        v-model="html"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import request from "@/utils/request";
import axios from "axios";
export default {
  name: "Ueditor",
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: "",
    },
    // 上传图片文件夹名称
    upImageFileName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
      html: "",
      ossUploadKey: {},
      toolbarConfig: {
        //菜单不展示的配置可以在这里添加，如果不知道可以在下面methods方法中打印菜单配置出来看
        excludeKeys: [
          "emotion",
          "insertImage",
          "insertVideo",
          "fullScreen",
          // "todo",
          // "insertTable",
          // "codeSelectLang",
          // "codeBlock",
          // "code",
        ],
        browerConnection: null,
      },
      editorConfig: {
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 上传图片
          uploadImage: {
            // server: props.uploadImagePath,
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: "test",
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 10 * 1024 * 1024, // 10M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: false,
            // 超时时间，默认为 10 秒
            timeout: 5 * 1000, // 5 秒
            // headers: {
            // Accept: "text/plain",
            // "Content-Type": "multipart/form-data",
            // "Access-Control-Allow-Origin": "*",
            // },
            async customUpload(file, insertFn) {
              const formData = new FormData();
              formData.append("files", file);
              const service = axios.create({
                baseURL: process.env.VUE_APP_BASE_API, // api的base_url
                timeout: 50000, // 请求超时时间
              });
              const data = await service({
                url: process.env.VUE_APP_BASE_API + "/Files/Upload",
                method: "post",
                data: formData,
                config: {
                  // headers: {
                  //     'Accept': "text/plain",
                  //     'Content-Type': 'multipart/form-data',
                  //     "Access-Control-Allow-Origin": "*",
                  //     'X-Token': ''
                  // }
                },
              }).then((res) => {
                let imgData = {};
                let _data = res.data;
                imgData = _data.result[0];
                // if(_data.Data && typeof _data.Data === 'string') {
                //     imgData = JSON.parse(_data.Data) || {}
                // } else {
                //     imgData = _data.Data || {}
                // }
                return imgData;
              });

              // const data = await this.updateImge(file, this.upImageFileName)
              if (data.filePath) {
                // process.env.VUE_APP_BASE_IMG_URL+'/'+
                insertFn(data.filePath, data.fileName);
              } else {
                this.$message.error(data.Message);
              }
            },
            // 上传之前触发
            onBeforeUpload(file) {
              console.log(file, "file");
              // file 选中的文件，格式如 { key: file }
              return file;
              // 可以 return
              // 1. return file 或者 new 一个 file ，接下来将上传
              // 2. return false ，不上传这个 file
            },
            // 上传进度的回调函数
            onProgress(progress) {
              // progress 是 0-100 的数字
              console.debug("progress", progress);
            },
            // 单个文件上传成功之后
            onSuccess(file, res) {
              console.debug(`${file.name} 上传成功`, res);
            },
            // 单个文件上传失败
            onFailed(file, res) {
              console.debug(`${file.name} 上传失败`, res);
            },
            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              this.$message.warning(`${err}`);
            },
          },
          // 上传视频
          // uploadVideo: {
          //   server: props.uploadVideoPath,
          //   // form-data fieldName ，默认值 'wangeditor-uploaded-video'
          //   fieldName: "your-custom-name",
          //   // 单个文件的最大体积限制，默认为 10M
          //   maxFileSize: 5 * 1024 * 1024, // 5M
          //   // 最多可上传几个文件，默认为 5
          //   maxNumberOfFiles: 3,
          //   // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
          //   allowedFileTypes: ["video/*"],
          //   // 跨域是否传递 cookie ，默认为 false
          //   withCredentials: true,
          //   // 超时时间，默认为 30 秒
          //   timeout: 15 * 1000, // 15 秒
          //   async customUpload(file, insertFn) {
          //     const data = await updateImge(file, props.upImageFileName)
          //     if (data.succeed) {
          //       insertFn(data.data.url, data.data.fileId)
          //     } else {
          //       ElMessage.warning(data.message);
          //     }
          //   },
          //   onError(file, err, res) {
          //     ElMessage.warning(`${err}`);
          //   },
          // },
          // 插入图片
          // insertImage: {
          //     onInsertedImage(imageNode) {
          //         if (imageNode == null) return
          //         const { src, alt, url, href } = imageNode
          //         store.commit('pushImageList', { src, alt })
          //     }
          // },
          // 插入视频
          // insertVideo: {
          //   onInsertedVideo(videoNode) {
          //     if (videoNode == null) return
          //     const { src } = videoNode
          //     debugger
          //     store.commit('pushVideoList', { src })
          //   }
          // }
        },
      },
    };
  },
  watch: {
    content(val) {
      this.html = val;
    },
  },
  mounted() {
    // this.html = JSON.parse(JSON.stringify(this.content))
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      //this.editor.getAllMenuKeys() 可以查询所有菜单配置
      // console.log('Editor菜单配置', this.editor.getAllMenuKeys())
      editor.setHtml(this.content);
    },
    onChange(editor) {
      console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
      // this.value = editor.getHtml();
      this.$emit("wangEditorChange", editor.getHtml());
    },
    updateImge(files, type) {
      const data = new FormData();
      data.append("files", files);
      return request({
        // url: 'https://frontapi.10nservice.com/api/UploadPolicyImg/',
        // url: policypro + uploader + type,
        method: "post",
        data: data,
        config: {
          headers: {
            Accept: "text/plain",
            "Content-Type": "multipart/form-data",
          },
        },
      });
    },
  },

  //组件销毁之前
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>
<style lang="scss">
.wrap {
  border: 1px solid #ccc;
  margin-top: 10px;

  .toolbar {
    border-bottom: 1px solid #ccc;
  }

  .editor {
    height: 400px;
    overflow-y: hidden;
  }
}
</style>