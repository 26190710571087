<!-- el-dialog 二次封装-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :close-on-click-modal="closeOnClickModal"
    @close="close"
  >
    <slot />
    <span v-if="showFooter" slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'EDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '操作提示'
    },
    width: {
      type: String,
      default: '50%'
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  watch: {
    visible: {
      immediate: true,
      deep: true,
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    open() {
      this.dialogVisible = true
      this.$emit('update:visible', true)
      this.$emit('open')
    },
    onConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
