<!-- el-drawer 二次封装-->
<template>
  <el-drawer
    :title="title"
    :visible.sync="dialogVisible"
    :size="width"
    @close="close"
  >
    <slot />
    <div class="footer" v-if="showFooter" >
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'EDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '操作提示'
    },
    width: {
      type: String,
      default: '50%'
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    //Drawer 打开的方向
    direction: {
      type: String,
      default: 'rtl'
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  watch: {
    visible: {
      immediate: true,
      deep: true,
      handler(val) {
        this.dialogVisible = val
      }
    }
  },
  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    open() {
      this.dialogVisible = true
      this.$emit('update:visible', true)
      this.$emit('open')
    },
    onConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer{
  position: fixed;
  bottom: 20px;
  right: 20px;
}
</style>
