import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 50000 // 请求超时时间
})
var curConfig = {}
// request拦截器
service.interceptors.request.use(config => {
  curConfig = config
  config.headers['tenantId'] = store.getters.tenantid
  // // 过滤 如果参数值为空或者null 则不传这个字段
  // if (config.params) {
  //   for (let key in config.params) {
  //     // 只判断空字符串和null类型
  //     if (config.params[key] === '' || config.params[key] === null) {
  //       delete config.params[key]
  //     }
  //   }
  // }
  // if (config.data) {
  //   for (let key in config.data) {
  //     if (config.data[key] === '' || config.data[key] === null) {
  //       delete config.data[key]
  //     }
  //   }
  // }

  if (store.getters.token) {
    config.headers['X-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  if (store.getters.isIdentityAuth) {
    config.headers['Authorization'] = 'Bearer ' + store.getters.oidcAccessToken
  }

  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
    * code为非200是抛错 可结合自己业务进行修改
    */
    const res = response.data
    if (res.code !== 200 && res.Status !== 200) {
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      // 后台代理接口有些会返回205
      if (res.Status == 205) {
        return response.data
      }
      if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 401) {
        MessageBox.confirm('登录已超时，可以【取消】继续留在该页面，或者【重新登录】', '超时提醒', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      } else {
        // 如果是流文件
        if (curConfig.octetStream) {
          return response.data
        }
        Message({
          message: res.message || res.msg,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    if (error.response && error.response.status == 400) {  //调用远程服务时，前端类型和后端不匹配

      var message = '<strong>' + error.response.data.detail + '</strong><br>'
      //异常信息需要特殊处理一下
      var errors = Object.entries(error.response.data.errors).map(([key, value]) => ({ key, value }));
      errors.forEach(item => {
        message += item.key + ":"

        item.value.forEach(dtl => {
          message += " " + dtl;
        })

        message += "<br>";
      })
      Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: 'error',
        duration: 10 * 1000
      })
    } else {
      // Message({
      //   message: '请先启动OpenAuth.WebApi，再刷新本页面，异常详情：' + error.message,
      //   type: 'error',
      //   duration: 10 * 1000
      // })
      Message({
        message: error.message,
        type: 'error',
        duration: 10 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
