<template>
	<div>
		<el-table :data="data" :show-summary="summary" style="width: 100%" tooltip-effect="dark" border>
			<el-table-column v-if="showIndex && data.length > 0" align="center" type="index" label="序号" />
			<template v-if="columns && columns.length > 0">
				<el-table-column
					v-for="(column, index) in columns.filter((item) => !['operator'].includes(item.type))"
					:key="'table__column_' + index"
					:align="column.align || 'center'"
					:label="column.label"
					:width="column.width"
					:prop="column.prop"
					:sortable="column.sortable"
					:render-header="column.renderHeader"
					:fixed="column.fixed"
					:min-width="column.minWidth || (column.label.length - 2) * 18 + 60"
					:show-overflow-tooltip="column.showOverflowTooltip === false ? false : true"
				>
					<template slot-scope="scope">
						<!-- 正常渲染 -->
						<template v-if="!column.render">
							<template v-if="column.formatter">
								<span v-html="column.formatter(scope.row, column)" />
							</template>
							<template v-else>
								<span v-text="scope.row[column.prop]" />
							</template>
						</template>
						<!-- render函数 -->
						<template v-else>
							<expand-dom :column="column" :row="scope.row" :render="column.render" :index="scope.$index" />
						</template>
					</template>
				</el-table-column>
				<!-- 操作栏 -->
				<el-table-column
					v-if="operators.outside.length > 0 || operators.inside.length > 0"
					:width="operators.width"
					fixed="right"
					label="操作"
					align="center"
				>
					<template slot-scope="scope">
						<template v-if="operators.outside.length > 0">
							<el-link
								v-for="(op, op_index) in operators.outside"
								v-show="inColumnCouldItemShow(op, scope)"
								:key="'outside-operator-' + op_index"
								:type="op.theme || 'primary'"
								style="margin-right: 5px; font-size: 12px"
								:underline="false"
								@click.stop="columnButtonsEventBroadcast(op, scope)"
							>
								{{ op.label }}
							</el-link>
						</template>
						<template v-if="operators.inside.length > 0">
							<el-dropdown @command="handleOperatorClick">
								<el-link type="primary" style="font-size: 12px" :underline="false" @click.stop>
									更多
									<e-icon icon="el-icon-arrow-down" />
								</el-link>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item
										v-for="(op, op_index) in operators.inside"
										v-show="inColumnCouldItemShow(op, scope)"
										:key="'outside-operator-' + op_index"
										:command="{ config: op, scope }"
									>
										<span style="font-size: 12px">{{ op.label }}</span>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</template>
				</el-table-column>
			</template>
		</el-table>
	</div>
</template>
<script>
export default {
	name: 'STable',
	components: {
		// render函数渲染组件
		expandDom: {
			functional: true,
			props: {
				row: Object,
				render: Function,
				index: Number,
				column: {
					type: Object,
					default: null,
				},
			},
			render: (h, ctx) => {
				const params = { row: ctx.props.row, index: ctx.props.index };
				if (ctx.props.column) params.column = ctx.props.column;
				return ctx.props.render(h, params);
			},
		},
	},
	props: {
		columns: {
			type: [Array],
			default: () => {
				return [];
			},
		},
		data: {
			type: Array,
			default: () => {
				return [];
			},
		},
		showIndex: {
			type: Boolean,
			default: false,
		},
		summary: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		operators() {
			// 权限处理
			const columns = this.columns;
			const meta = this.$route.meta || {};
			const buttons = meta.buttons || [];
			const outside = [];
			const inside = [];
			let width = 0;
			columns.map((item) => {
				if (item.type === 'operator') {
					if (buttons.length === 0 || buttons.includes(item.event)) {
						if (item.inset) {
							inside.push(item);
						} else {
							outside.push(item);
							const tempWidth = item.label ? item.label.length * 20 : 60;
							width += Number(item.width) || tempWidth;
						}
					}
				}
			});

			if (inside && inside.length > 0) {
				width += 80;
			}
			width = width > 80 ? width : 80;
			return {
				outside,
				inside,
				width,
			};
		},
	},
	data() {
		return {};
	},
	methods: {
		inColumnCouldItemShow(config, scope) {
			const { show, prop, activeValue } = config;
			if (prop !== undefined && activeValue !== undefined) return scope.row[prop] === activeValue;
			if (show === undefined || show === null) return true;
			if (typeof show === 'function') return show(scope);
			return show;
		},
		columnButtonsEventBroadcast(config, scope) {
			if (!config.event) return;
			if (typeof config.event === 'function') return event(scope);
			else {
				this.$emit(config.event, scope.row);
			}
		},
	},
};
</script>
