<template>
  <div class="app_container">
    <div class="container_top">
      <slot name="top"></slot>
    </div>
    <div class="container_main">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.app_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .container_top {
    width: 100%;
    height: auto;
    padding: 0.625rem;
    background-color: #f8f8f8;
  }
  .container_main {
    flex: 1;
    overflow-y: auto;
  }
}
</style>