<template>
  <div class="app-container flex-item flex-column">
    <div class="table-box">
      <el-table
        ref="tableData"
        v-loading="loading"
        :row-key="rowKey"
        tooltip-effect="dark"
        :style="{ width: '100%' }"
        :show-header="showHeader"
        :data="list"
        :height="tableHeight"
        :summary-method="getSummaries"
        :show-summary="showSummary"
        :cell-style="cellStyle"
        :cell-class-name="tableCellClassName"
        :span-method="arraySpanMethod"
        :border="border"
        :highlight-current-row="highlightCurrentRow"
        v-bind="tableInfo"
        :stripe="isStripe"
        v-on="events"
      >
        <slot name="expand" />
        <!-- 多选 -->
        <el-table-column
          v-if="needSelect"
          :selectable="selectable"
          type="selection"
          width="55"
          :reserve-selection="isReserve"
          align="center"
        />
        <el-table-column
          v-if="hasIndex"
          label="序号"
          width="50"
          type="index"
          align="center"
        />
        <template v-for="(item, index) in tableColumn">
          <!-- 此列需要自定义 notTooltip  超出行是否隐藏 -->
          <!-- <el-table-column v-if="item.isSlot" :key="'%' + index" :show-overflow-tooltip="!item.notTooltip"
                        v-bind="item" v-on="events">
                        <template slot-scope="{ row, $index }">
                            <ex-slot v-if="item.render" :render="item.render" :row="row" :index="$index" :column="item"
                                :class="item.prop" :default-value="item.defaultValue" />
                            <slot v-else :name="item.prop" :row="row" />
                        </template>
</el-table-column> -->
          <el-table-column
            v-bind="item"
            v-on="events"
            v-if="item.render"
            :align="item.align ? item.align : 'center'"
            :show-overflow-tooltip="item.notTooltip ? false : true"
          >
            <template slot-scope="{ row, $index }">
              <expand-dom
                :column="item"
                :row="row"
                :render="item.render"
                :index="$index"
              />
            </template>
          </el-table-column>
          <!-- 正常列 -->
          <el-table-column
            v-else
            :key="'%%' + index"
            :show-overflow-tooltip="item.notTooltip ? false : true"
            v-bind="item"
            :align="item.align ? item.align : 'center'"
            v-on="events"
          />
        </template>
        <el-table-column
          v-if="hasOperation"
          label="操作"
          :width="operationWidth"
          align="center"
        >
          <!-- <template v-if="!btnButton || btnButton.length === 0" slot-scope="scope">
            <slot name="operation" :row="scope.row" :index="scope.$index" />
          </template> -->
          <template
            v-if="btnButton.length"
            slot-scope="{ row, column, $index }"
          >
            <el-button
              v-for="(value, i) in btnButton"
              :key="'$' + i"
              v-if="value.isShow && value.isShow(row, column, $index)"
              size="mini"
              :type="value.type"
              :icon="value.icon"
              :class="value.class"
              :disabled="value.disabled && value.disabled(row, column, $index)"
              @click.stop="value.callback(row, column, $index)"
            >
              <span v-if="value.type !== 'icon'">{{ value.text }}</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div v-if="isNeedPagination" class="pagination-container">
        <el-pagination
          ref="pagination"
          background
          :page-sizes="pageSizes"
          :page-size.sync="computedPageSize"
          :hide-on-single-page="isSinglePageHide"
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="computedCurrentPage"
          :total="total"
          :small="small"
          :pager-count="pagerCount"
          @current-change="currentChange"
          @size-change="sizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import { mapState } from "vuex";
import request from "@/utils/request";

// 自定义组件的内容
const exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null,
    },
    defaultValue: [Number, String],
  },

  render: (h, ctx) => {
    const params = {
      row: ctx.props.row,
      index: ctx.props.index,
    };
    const defaultValue = ctx.props.defaultValue;
    params.column = ctx.props.column || {};
    return h(
      "div",
      {
        class: [
          params.column.prop || "",
          params.column.class || params.column.className || "",
        ].join(""),
      },
      [ctx.props.render(h, params) || defaultValue]
    );
  },
};
export default {
  name: "PublicTable",
  components: {
    "ex-slot": exSlot,
    // render函数渲染组件
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null,
        },
      },
      render: (h, ctx) => {
        const params = { row: ctx.props.row, index: ctx.props.index };
        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render(h, params);
      },
    },
  },
  props: {
    // key（唯一key值，和reserve-selection搭配使用）
    rowKey: {
      type: String,
      default: "id",
    },
    // 多选框是否记住上一页数据(必须与row-key唯一值搭配使用)
    isReserve: {
      type: Boolean,
      default: false,
    },
    // 获取列表的接口或者方法
    getListMethod: {
      type: [Function, String],
      default: "",
    },
    // 初始化的参数
    params: {
      type: Object,
      default: null,
    },
    // 列表数据的字段，默认是data，有些接口可能是result等字段
    dataKey: {
      type: String,
      default: "data",
    },
    // 单元格的 style 的回调方法
    cellStyle: {
      type: [Object, Function],
      default: () => ({}),
    },
    small: {
      type: Boolean,
      default: false,
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true,
    },
    // 是否显示合计
    showSummary: {
      type: Boolean,
      default: false,
    },
    // 是否要高亮当前行
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },
    // 自定义的合计计算方法
    getSummaries: {
      type: Function,
      default: () => {},
    },
    // 获取表格行列索引值
    tableCellClassName: {
      type: Function,
      default: () => {},
    },
    // 合并行或列
    arraySpanMethod: {
      type: Function,
      default: () => {},
    },
    // 是否需要多选
    needSelect: {
      type: Boolean,
      default: true,
    },
    // 是否select需要特殊判断
    selectable: {
      type: Function,
      default: () => {
        return true;
      },
    },
    // 是否需要序号
    hasIndex: {
      type: Boolean,
      default: true,
    },
    // 默认是否选中第一列
    isSetCurrentRow: {
      type: Boolean,
      default: false,
    },
    // 是否需要分页
    isNeedPagination: {
      type: Boolean,
      default: true,
    },
    // 是否单页隐藏，默认为true
    isSinglePageHide: {
      type: Boolean,
      default: false,
    },
    // 每页多少数据
    pageSizes: {
      type: Array,
      required: false,
      default: () => [10, 20, 40, 80, 100],
    },
    tableInfo: {
      type: Object,
      default: () => {},
    },

    tableData: {
      type: Array,
      default: () => [],
    },
    // 表格展示数据
    tableColumn: {
      type: Array,
      default: () => [],
    },
    // 是否需要操作列
    hasOperation: {
      type: Boolean,
      default: true,
    },
    // 是否需要边框
    border: {
      type: Boolean,
      default: true,
    },
    // 操作列
    btnButton: {
      type: Array,
      default: () => [],
    },
    // 操作列宽度
    operationWidth: {
      type: String,
      default: "230",
    },
    // 操作
    fixed: {
      type: [Boolean, String],
      default: false,
    },

    // 是否为斑马纹 table
    isStripe: {
      type: Boolean,
      default: false,
    },
    // 接口类型
    method: {
      type: String,
      default: "get",
    },
    baseURL: {
      type: String,
      default: process.env.VUE_APP_SN_API,
    },
    pageKey: {
      type: String,
      default: "page",
    },
    limitKey: {
      type: String,
      default: "limit",
    },
    initLoad: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // fontSize: sessionStorage.getItem('fontSize') || '',
      requestParams: {},
      total: 0,
      list: [],
      loading: false,
      events: {
        "row-dblclick": (row) => {
          //双击表格项触发的函数
          // console.log('双击表格项', row)
        },
        "row-click": (row) => {
          // 单机表格项触发的函数
          // console.log('单击表格项', row)
          if (this.needSelect) this.toggleRowSelection(row);
          this.$emit("rowClick", row);
        },
        "selection-change": (row) => {
          this.handleSelectionChange(row);
        },
      },
      ids: [], // 选中的列表id，至于是哪个字段主要由属性pk确定，默认是id
    };
  },
  // data() {
  //   return {
  //     // eslint-disable-next-line vue/no-dupe-keys
  //     tableData: []
  //   }
  // },
  computed: {
    computedCurrentPage: {
      get() {
        return this.requestParams[this.pageKey];
      },
      set(val) {
        this.requestParams[this.pageKey] = val;
      },
    },
    computedPageSize: {
      get() {
        return this.requestParams[this.limitKey];
      },
      set(val) {
        this.requestParams[this.limitKey] = val;
      },
    },
    tableHeight() {
      console.log(
        "height",
        !this.isNeedPagination ? "100%" : "calc(100% - 57px)"
      );
      return !this.isNeedPagination ? "100%" : "calc(100% - 57px)";
    },
  },
  watch: {
    "$parent.activeName"() {
      this.$nextTick(() => {
        this.$refs.tableData.doLayout();
      });
    },
  },
  created() {
    this.requestParams[this.pageKey] = 1;
    this.requestParams[this.limitKey] = 20;
  },
  mounted() {
    if (this.initLoad) {
      this.reload();
      if (this.isSetCurrentRow) {
        setTimeout(() => {
          this.$refs.tableData.setCurrentRow(this.tableData[0]);
        }, 500);
      }
    }
  },
  methods: {
    searchReLoad() {
      this.requestParams = this.$options.data().requestParams;
      this.reload();
    },
    search() {
      this.computedCurrentPage = 1;
      this.$nextTick(() => {
        this.reload();
      });
    },
    reload() {
      this.loading = true;
      if (!this.getListMethod) {
        this.loading = false;
        return false;
      }
      if (this.params) {
        this.requestParams = { ...this.requestParams, ...this.params };
      }
      let res = "";
      if (typeof this.getListMethod === "string") {
        if (this.method === "get") {
          res = request({
            url: this.getListMethod,
            method: "get",
            params: this.requestParams,
            baseURL: this.baseURL,
          });
        } else {
          res = request({
            url: this.getListMethod,
            method: "post",
            data: this.requestParams,
            baseURL: this.baseURL,
          });
        }
      } else {
        res = this.getListMethod(this.requestParams);
      }
      if (typeof res.then === "function") {
        res
          .then((res) => {
            if (res.data) {
              this.loading = false;
              this.list =
                this.dataKey === "data"
                  ? res.data
                  : res instanceof Array
                  ? res
                  : res[this.dataKey] || [];
              if (res.count !== undefined) {
                this.total = res.count;
              } else {
                this.total = this.list.length;
              }
            } else if (res.Data) {
              let data = JSON.parse(res.Data);
              this.loading = false;
              this.list = data || [];
              if (res.RecordCount !== undefined) {
                this.total = res.RecordCount;
              } else {
                this.total = this.list.length;
              }
            } else if (res.result) {
              this.list = res.result || [];
              this.loading = false;
            } else {
              this.list = [];
              this.loading = false;
            }
            this.$emit("getList", this.list, this.total);
            this.$nextTick(() => {
              this.$refs.tableData.doLayout();
            });
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    // 页面切换事件  通过 @currentChange 绑定
    currentChange(val) {
      this.requestParams[this.pageKey] = val;
      this.reload();
    },
    // 每页条数切换事件，通过@sizeChange 绑定
    sizeChange(val) {
      this.requestParams[this.limitKey] = val;
      this.requestParams[this.pageKey] = 1;
      this.reload();
    },
    getTableRef() {
      return this.$refs.tableData;
    },
    /**
     * table	用于多选表格，清空用户的选择
     */
    clearSelection() {
      this.getTableRef().clearSelection();
    },
    /**
     * table	用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
     */
    toggleRowSelection(row, flag) {
      this.getTableRef().toggleRowSelection(row, flag);
    },
    /**
     * table - 复选框选中触发方法
     */
    handleSelectionChange(rows) {
      this.ids = rows.map((row) => {
        return row[this.rowKey];
      });
      this.$emit("on-selection-change", this.ids, rows);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-box {
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

::v-deep .el-table {
  border: 1px solid #dfe6ec;
}

::v-deep .el-table th {
  padding: 0;
  height: 40px;
  background: #f2f4fa;
  box-shadow: 0px 1px 0px 0px #ebedf0;
  // color: #191f25;
  // color: #000;
  // font-weight: 400;
}

::v-deep .el-table td {
  padding: 0;
  height: 40px;
}

::v-deep .el-table {
  width: 100%;

  .maxMinBtn {
    padding: 0 5px;
  }
}

::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background-color: #e6f3e2;
}

::v-deep .el-table__body tr.current-row {
  .flex_div {
    background: rgba(21, 91, 212, 0.3) !important;
  }

  td {
    background: rgba(21, 91, 212, 0.3) !important;
  }

  td {
    background: rgba(21, 91, 212, 0.2) !important;
  }
}

.pageination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  background-color: #fff;
}

.pagination-container {
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination-container.hidden {
  display: none;
}
</style>